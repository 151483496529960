import React, { useState } from "react";
import axios from "axios";

const MetaForm = () => {
  const [title, setTitle] = useState("");
  const [articleType, setArticleType] = useState("");
  const [articles, setArticles] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const handleTitleChange = (e) => setTitle(e.target.value);
  const handleArticleTypeChange = (e) => setArticleType(e.target.value);

  const generateMetaData = async () => {
    if (!title.trim() || !articleType) {
      alert(
        "Please enter a title and select an article type before generating meta data."
      );
      return;
    }
    setIsLoading(true);
    try {
      const response = await axios.post(
        "https://api.openai.com/v1/chat/completions",
        {
          model: "gpt-3.5-turbo",
          messages: [
            {
              role: "system",
              content:
                "You are an AI-powered assistant designed specifically for generating Search Engine Optimization (SEO) meta titles and descriptions. Your capabilities include understanding the context and content of web pages, analyzing keywords, and producing meta titles and descriptions that are optimized for search engine rankings. Your goal is to enhance the visibility of web pages in search engine results by crafting concise, relevant, and keyword-rich meta titles and descriptions that accurately reflect the content of the page, engage potential visitors, and comply with SEO best practices. Make sure to provide easy to read text, without complex words. Banned words are all words like delve, uncover, navigate, cutting edge, unleash, unravel etc, demistifying. Make sure the readability standard is at maximum grade 9 of Flesch Kincaid reading score.",
            },
            {
              role: "user",
              content: `Generate 10 SEO meta titles and descriptions for a ${articleType} type article titled "${title}". Each title up to 60 characters and each description up to 155 characters. Please don't use # or numbered lists to format anything. For example, Title: [title], Description: [description]. Glossary type is about explaining what is the term, definition etc so it needs to be formatted like: what is term x? definitions, examples or any sections that that goes into that. Blog posts are starndard metas, product types are more CTR oriented towards selling the product and marketing pages are more about explaining what the page is about on a high level. Banned words are:
              
              delve, uncover, navigate, cutting edge, unleash, unravel, demistifying. Make sure the readability standard is at grade 8 of Flesch Kincaid reading score.`,
            },
          ],
        },
        {
          headers: {
            Authorization: `Bearer ${process.env.REACT_APP_OPENAI_API_KEY}`,
            "Content-Type": "application/json",
          },
        }
      );

      const lines = response.data.choices[0].message.content
        .trim()
        .split("\n")
        .filter((line) => line);
      let parsedArticles = [];
      for (let i = 0; i < lines.length; i += 2) {
        if (lines[i + 1]) {
          parsedArticles.push({
            title: lines[i].replace("Title: ", ""),
            description: lines[i + 1].replace("Description: ", ""),
          });
        }
      }

      setArticles(parsedArticles);
    } catch (error) {
      console.error("Error generating meta data:", error);
      alert(
        "Failed to generate meta data. Check the console for more information."
      );
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="max-w-4xl mx-auto p-5 space-y-4">
      <select
        value={articleType}
        onChange={handleArticleTypeChange}
        className="w-full p-2 border text-slate-900 border-gray-300 rounded shadow-sm"
      >
        <option value="">Select Article Type</option>
        <option value="Blog Post">Blog Post</option>
        <option value="Glossary Term">Glossary Term</option>
        <option value="Single Product">Single Product</option>
        <option value="Product Review">Product Review</option>
        <option value="Marketing Page">Marketing Page</option>
      </select>

      <input
        type="text"
        value={title}
        onChange={handleTitleChange}
        placeholder="Enter article title"
        className="w-full p-2 border text-slate-900 border-gray-300 rounded shadow-sm mb-4"
      />
      <button
        onClick={generateMetaData}
        disabled={!title.trim() || !articleType}
        className={`w-full ${
          !title.trim() || !articleType
            ? "bg-purple-300"
            : "bg-purple-700 hover:bg-purple-800"
        } text-white font-bold py-6 px-4 rounded mb-4`}
      >
        Generate Meta Data
      </button>

      {isLoading ? (
        <div className="flex justify-center items-center">
          <div className="animate-ping rounded-full mr-4 h-2 w-2 border-4 border-blue-200"></div>
          <div className="animate-ping rounded-full mr-4 h-2 w-2 border-4 border-blue-200"></div>
          <div className="animate-ping rounded-full mr-4 h-2 w-2 border-4 border-blue-200"></div>
          <p className="text-white-300">Generating...</p>
        </div>
      ) : (
        articles.map((article, index) => (
          <div
            key={index}
            className="bg-white text-slate-900 p-4 rounded-lg shadow mb-4"
          >
            <p className="mt-2 font-bold text-2xl text-gray-600">
              {article.title}
            </p>
            <p className="mt-2 text-2xl text-gray-600">{article.description}</p>
          </div>
        ))
      )}
    </div>
  );
};

export default MetaForm;
