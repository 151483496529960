import React from "react";
import "./App.css";
import MetaForm from "./MetaForm";
import logo from "./beki-new-logo.png"; // Add this line

function App() {
  return (
    <div className="App bg-slate-50">
      <header className="App-header">
        <h1 className="text-4xl font-semibold text-slate-50">
          <strong>FREE</strong> Meta Title & Description Generator
        </h1>
        <MetaForm />
        <div>
          <a href="https://www.beki.io">
            <p className="text-purple-300 text-xl content-center underline">
              Build internal links 10x faster
            </p>
          </a>
        </div>
      </header>
    </div>
  );
}

export default App;
